import { LOCALES } from "../constants";

const es = {
  [LOCALES.SPANISH]: {
    menu: {
      home: "Inicio",
      about: "Sobre mí",
      skills: "Habilidades",
      projects: "Proyectos",
      experience: "Experiencia",
      contact: "Contacto",
      resume: "CV",
    },
    home: {
      presentation: "¡Hola! Me llamo ",
      profession: "Yo soy ",
      contactBtn: "Contáctame",
    },
    about: {
      heading: "Sobre mí",
      presentation:
        "¡Hola! 👋🏻 Me llamo Dilyana y soy desarrolladora full-stack, basada en Niza, Francia.",
      info1:
        "Me apasionan la programación 💻 y las lenguas extranjeras 🗺️ . Me gusta crear aplicaciones web y móviles estéticas y mi objetivo es escribir un código bien estructurado y de calidad y mejorarme continuamente.",
      info2:
        "Durante los últimos cinco años, he desarrollado y colaborado en numerosos proyectos, incluyendo servicios de monitoreo en tiempo real de para miles de máquinas, y dos plataformas SaaS B2B que procesan más de 30.000 solicitudes diarias. Diseñé e implementé una plataforma SaaS de chatbot de voz totalmente personalizable, mejorando significativamente los indicadores de atención al cliente. Mi experiencia también incluye liderar proyectos de I+D y pruebas de concepto, implementar una pipeline CI/CD que redujo los tiempos de implementacón, gestionar múltiples proyectos concurrentes en un entorno Agile, y contribuir significativamente a varias plataformas SaaS, incluyendo aquellas para salud y atención social, y para vídeo bajo demanda y streaming. También he mantenido un servidor VoIP y desarrollado prototipos, demostrando mi experiencia en desarrollo front-end y back-end para aplicaciones web y móviles.",
      info3:
        "Hablo francés 🇫🇷 , inglés 🇬🇧 , español 🇪🇸 y búlgaro 🇧🇬 fluido. Tengo un Master en ",
      mastersDegree: '"Estudios Hispánicos e Hispano-Americanos" ',
      info4: "y un Título Profesional en ",
      bachelorsDegree: '"Desarrollo y Diseño de Aplicaciones Web y Móviles".',
      info5:
        "Corro principalmente con código y buen café ☕ . Soy motivada y me gusta adquirir nuevas habilidades, adoptar buenas prácticas 📚 , mantenerme al día con las últimas tecnologías y ponerlas en práctica desarrollando diferentes proyectos 👩🏼‍🔬 .",
      interestsHeading: "Intereses : ",
      info6:
        'programaciòn, aprentizaje, desarrollo personal, idiomas extranjeras,  videojuegos, retro gaming, juegos de mesa y electrónica. Bueno, soy una "geek" 👩🏼‍💻 .',
    },
    skills: {
      heading: "Habilidades",
    },
    projects: {
      heading: "Proyectos",
      technologies: "Tecnologías",
      description: "Descripción",
      visitSiteBtn: "Visitar Sitio",
      seeMoreBtn: "Ver Más",
      watchDemoBtn: "Ver Demo",
      checkGithubBtn: "Ver Github",
    },
    experience: {
      heading: "Experiencia",
    },
    contact: {
      heading: "Contáctame",
      form: {
        name: "Nombre y Apellido",
        email: "Correo Electrónico",
        subject: "Asunto",
        message: "Mensage",
        sendButton: "Enviar",
        confirmation: "Mensaje Enviado!",
        error: "Ha surgido un error.",
      },
    },
    flags: {
      british: "bandera británica",
      bulgarian: "bandera búlgara",
      french: "bandera francesa",
      spanish: "bandera española",
    },
  },
};

export default es;
