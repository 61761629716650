import { translate } from "../../utils/translate";

import "./Info.scss";

const AboutInfo = () => (
  <div className="AboutInfo">
    <span className="AboutInfo_content">
      <h3 className="AboutInfo_heading">{translate("about.presentation")}</h3>
      <p className="AboutInfo_info">
        {translate("about.info1")}
        <br />
        <br />
        {translate("about.info2")}
        <br />
        <br />
        {translate("about.info3")} <i>{translate("about.mastersDegree")}</i>
        {translate("about.info4")} <i>{translate("about.bachelorsDegree")}</i>
        <br />
        <br />
        {translate("about.info5")}
        <br />
        <br />
        <strong>{translate("about.interestsHeading")}</strong>
        {translate("about.info6")}
      </p>
    </span>
  </div>
);

export default AboutInfo;
