import { LOCALES } from "../constants";

const bg = {
  [LOCALES.BULGARIAN]: {
    menu: {
      home: "Начало",
      about: "За мен",
      skills: "Умения",
      projects: "Проекти",
      experience: "Опит",
      contact: "Контакт",
      resume: "CV",
    },
    home: {
      presentation: "Здравей! Казвам се ",
      profession: "и съм ",
      contactBtn: "Свържи се с мен",
    },
    about: {
      heading: "За мен",
      presentation:
        "Здравей! 👋🏻 Казвам се Диляна и съм full-stack разработчик. Живея в Ница, Франция",
      info1:
        "Моята страст са програмирането 💻 и чуждите езици 🗺️ . Обичам да създавам естетически и функционални уеб и мобилни апликации и се стремя да пиша качествен, добре структуриран код, както и постоянно да се усъвършенствам.",
      info2:
        "През последните пет години разработих и участвах в множество проекти, включително високопроизводителни системи за мониторинг на машини, инсталирани на хиляди машини, и две B2B SaaS платформи, обработващи над 30 000 заявки дневно. Проектирах и имплементирах напълно персонализируема SaaS платформа за гласови чатботове, значително подобрявайки времето за обслужване на клиенти. Опитът ми включва и ръководене на R&D проекти и проекти за концептуално доказване (PoC), имплементация на CI/CD pipeline, управление на множество едновременни проекти в Agile среда, и значителен принос към няколко SaaS платформи, включително за здравеопазване и социални грижи, както и за видео по заявка (VOD) и стрийминг. Поддържах също и VoIP сървър и разработих прототипи, демонстрирайки експертизата си в front-end и back-end разработката за уеб и мобилни приложения.",
      info3:
        "Говоря свободно френски 🇫🇷 , английски 🇬🇧 , испански 🇪🇸 и български 🇧🇬. Имам Магистърска степен по",
      mastersDegree: '"Испанистика и Ибероамериканистика" ',
      info4: "и професионално ниво Бакалавър по",
      bachelorsDegree: '"Разработване и Дизайн на Уеб и Мобилни Апликации".',
      info5:
        '"Храня се" главно с код и добро кафе ☕ . Силно мотивирана съм, обичам да усвоявам нови умения 📚 , да съм в крак с най-новите технологии и да ги прилагам на практика чрез различни проекти 👩🏼‍🔬 .',
      interestsHeading: "Интереси: ",
      info6:
        'програмиране, обучение, личностно развитие, чужди езици, видео игри, ретро гейминг, бордни игри и електроника. Накратко, аз съм "geek" 👩🏼‍💻 .',
    },
    skills: {
      heading: "Умения",
    },
    projects: {
      heading: "Проекти",
      technologies: "Технологии",
      description: "Описание",
      visitSiteBtn: "Посети сайта",
      seeMoreBtn: "Виж повече",
      watchDemoBtn: "Гледай Демо",
      checkGithubBtn: "Виж Github",
    },
    experience: {
      heading: "Опит",
    },
    contact: {
      heading: "Свържете се с мен",
      form: {
        name: "Име и Фамилия",
        email: "Email Адрес",
        subject: "Тема",
        message: "Съобщение",
        sendButton: "Изпрати",
        confirmation: "Изпратено!",
        error: "Възникна грешка.",
      },
    },
    flags: {
      british: "британско знаме",
      bulgarian: "българско знаме",
      french: "френско знаме",
      spanish: "испанско знаме",
    },
  },
};

export default bg;
