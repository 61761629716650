import { LOCALES } from "../translations/constants";

const data = {
  [LOCALES.ENGLISH]: [
    {
      institution: "Quantoom (ex Sadigh Group)",
      location: "Dijon, France (Full-Remote)",
      title: "Lead Full-Stack Developer",
      description: `- developed and maintained a high-performance machine monitoring service deployed across 4300+ machines, using C# (for Windows), Golang (for Linux), and Swift (for Mac)
- architected and maintained the front-end (React/Redux) and back-end (multiple micro-services in Node/MongoDB/WebSockets) of two B2B SaaS platforms: processing  more than 30K requests per day
- designed and implemented a fully customizable phone voice bot SaaS platform: Leveraged Next, TypeScript, Node, MongoDB, PostgreSQL and Asterisk IPBX, reducing average call resolution time by 20% and improving customer satisfaction
- maintained an Asterisk IPBX VOIP server serving our client’s customer support center
- spearheaded research and development (R&D) and proof-of-concept (PoC) projects: successfully completed two PoCs, leading to the adoption of some new technologies
- implemented and maintained a CI/CD pipeline using Github Actions reducing deployment time by 30%
- led project management and communication with clients for multiple concurrent projects in an Agile Environment`,
      date: "June 2023 - Today",
      tag: "work",
      studies: false,
    },
    {
      institution: "Quantoom (ex Sadigh Group)",
      location: "Dijon, France (Full-Remote)",
      title: "Full-Stack Developer",
      description: `- developed a prototype of a machine performance monitoring service and remote-control application for Windows using Golang, a Websocket server (Node) and a web platform (React) that allows a user to securely take control over specific machine
- collaborated on the development of a SaaS platform for health and social care companies, contributing to both the front-end (React/Redux) and back-end (micro-services using Node/Express, MongoDB)
- implemented new features and fixed bugs on a cross-platform mobile application in React Native`,
      date: "April 2021 - June 2023",
      tag: "work",
      studies: false,
    },
    {
      institution: "53JS",
      location: "Nice, France",
      title: "Full-Stack JavaScript Developer",
      description:
        "- collaborated on the development of a SaaS VOD platform, contributing significantly to both the front-end (React/Redux) and back-end (micro-services using Node/Express, MongoDB). This included building a responsive user interface for streaming and on-demand video playback, implementing real-time communication features (WebRTC, WebSockets), and developing key API endpoints",
      date: "December 2019 - December 2020",
      tag: "work",
      studies: false,
    },
    {
      institution: "Trade and Services Business School (ICS)",
      location: "Nice, France",
      title: "Web and Mobile Application Engineering",
      description: "Bachelor's level professional degree (Bac+3)",
      date: "November 2019 - November 2020",
      tag: "studies",
      studies: true,
    },
    {
      institution: "Rheveo",
      location: "Sophia Antipolis, France",
      title: "Front-End Developer",
      description:
        "- collaborated on the development of the UI of a web platform that uses Artificial Intelligence and Psychology to improve the performance of the organizations through their collaborators' engagement. (Vue.js, Element UI, JavaScript, CSS/Sass, Bootstrap, HTML)",
      date: "June 2019 - July 2019",
      tag: "work",
      studies: false,
    },
    {
      institution: "Trade and Services Business School (ICS)",
      location: "Nice, France",
      title: "Web and Mobile Web Development",
      description: "Professional degree (Bac+2)",
      date: "March 2019 - October 2019",
      tag: "studies",
      studies: true,
    },
    {
      institution: "Inti Formation",
      location: "Sophia Antipolis, France",
      title: "Java/JEE Software Development",
      description: "Java/JEE Research and Development training",
      date: "December 2018 - March 2019",
      tag: "studies",
      studies: true,
    },
    {
      institution: "University of Nice Sophia Antipolis",
      location: "Nice, France",
      title: "Master's degree in Hispanic and Latin American Studies",
      description:
        "Research oriented master in Hispanic and Latin American Languages, Literatures and Linguistics",
      date: "August 2016 - September 2018",
      tag: "studies",
      studies: true,
    },
    {
      institution: "TELUS International Europe",
      location: "Sofia, Bulgaria",
      title: "Fitbit Technical Support Specialist",
      description:
        "Technical support specialist for the American company Fitbit. Support in Spanish, English and French via phone, email and over the social networks.",
      date: "September 2015 - August 2016",
      tag: "work",
      studies: false,
    },
    {
      institution: 'Sofia University "St. Kliment Ohridski"',
      location: "Sofia, Bulgaria",
      title: "Bachelor's degree in Hispanic and Latin American Studies (Bac+4)",
      description: "Spanish Translation and Teaching",
      date: "October 2011 - September 2015",
      tag: "studies",
      studies: true,
    },
    {
      institution: "University of Valencia",
      location: "Valencia, Spain",
      title: "Hispanic and Latin American Studies",
      description: "Spanish Linguistics (Erasmus)",
      date: "September 2013 - June 2014",
      tag: "studies",
      studies: true,
    },
  ],
  [LOCALES.FRENCH]: [
    {
      institution: "Quantoom (ex Sadigh Group)",
      location: "Dijon, France (Full-Remote)",
      title: "Lead Développeuse Full-Stack",
      description: `- développement et maintenance d'un service de monitoring machine déployé sur plus de 4300 machines, utilisant C# (pour Windows), Golang (pour Linux) et Swift (pour Mac)
- conception et maintenance du front-end (React/Redux) et du back-end (microservices en Node/MongoDB/WebSockets) de deux plateformes SaaS B2B : déservant de plus de 30K requêtes par jour
- conception et implémentation d'une plateforme SaaS de bot vocal téléphonique entièrement personnalisable : utilisation de Next, TypeScript, Node, MongoDB, PostgreSQL et Asterisk IPBX VOIP, réduisant le temps moyen de résolution des appels de 20% et améliorant la satisfaction client
- maintenance d'un serveur Asterisk IPBX VOIP au service du centre de support client de nos clients
- direction de projets de recherche et développement (R&D) et de preuve de concept (PoC) : réalisation réussie de deux PoC, conduisant à l'adoption de nouvelles technologies
- implémentation et maintenance d'un pipeline CI/CD utilisant GitHub Actions, réduisant le temps de déploiement de 30%
- gestion de projet et communication avec les clients pour plusieurs projets simultanés dans un environnement Agile`,
      date: "Juin 2023 - Aujourd'hui",
      tag: "emploi",
      studies: false,
    },
    {
      institution: "Quantoom (ex Sadigh Group)",
      location: "Dijon, France (Full-Remote)",
      title: "Développeuse Full-Stack",
      description: `- développement d'un prototype de service de monitoring des machines et d'une application de contrôle à distance pour Windows utilisant Golang, un serveur Websocket (Node) et une plateforme web (React) permettant à un utilisateur de prendre le contrôle sécurisé d'une machine spécifique
- collaboration au développement d'une plateforme SaaS pour les entreprises du secteur socio-médical, contribuant à la fois au front-end (React/Redux) et au back-end (Node/Express, MongoDB)
- implémentation de nouvelles fonctionnalités et correction de bugs sur une application mobile multiplateforme en React Native`,
      date: "Avril 2021 - Juin 2023",
      tag: "emploi",
      studies: false,
    },
    {
      institution: "53JS",
      location: "Nice, France",
      title: "Développeuse Javascript Full-Stack",
      description:
        "- développement d'une plateforme SaaS de streaming et VOD : contribution significative au front-end (React/Redux) et back-end (Node.js/Express.js, MongoDB), incluant interface utilisateur responsive, communication temps réel (WebRTC, WebSockets) et multiples endpoints API",
      date: "Décembre 2019 - Décembre 2020",
      tag: "emploi",
      studies: false,
    },
    {
      institution: "Institut du Commerce et des Services (ICS)",
      location: "Nice, France",
      title: "Concepteur Dévelopeur d'Applications",
      description: "Titre Professionnel (niveau Bac+3)",
      date: "Novembre 2019 - Novembre 2020",
      tag: "études",
      studies: true,
    },
    {
      institution: "Rheveo",
      location: "Sophia Antipolis, France",
      title: "Développeuse Front-End",
      description:
        "participation au développement de l'Interface Utilisateur d'une plateforme web qui réunit Intelligence Artificielle et Psychologie, afin d'évaluer et d'améliorer la performance des entreprises à travers l'engagement de leurs collaborateurs. (Vue.js, Element UI, JavaScript, CSS/Sass, Bootstrap, HTML)",
      date: "Juin 2019 - Juillet 2019",
      tag: "emploi",
      studies: false,
    },
    {
      institution: "Institut du Commerce et des Services (ICS)",
      location: "Nice, France",
      title: "Développeur Web et Web Mobile",
      description: "Titre Professionnel (niveau Bac+2)",
      date: "Mars 2019 - Octobre 2019",
      tag: "études",
      studies: true,
    },
    {
      institution: "Inti Formation",
      location: "Sophia Antipolis, France",
      title: "Développeur Java/JEE",
      description: "Formation en développement de logiciels en Java/JEE",
      date: "Décembre 2018 - Mars 2019",
      tag: "études",
      studies: true,
    },
    {
      institution: "Université de Nice Sophia Antipolis",
      location: "Nice, France",
      title: "Master en Études Hispaniques et Hispano-Américaines",
      description:
        "(Mention Très Bien) Master Recherche en Langues, Litteratures et Linguistiques Hispaniques et Hispano-Amériacaines",
      date: "Août 2016 - Septembre 2018",
      tag: "études",
      studies: true,
    },
    {
      institution: "TELUS International Europe",
      location: "Sofia, Bulgarie",
      title: "Spécialiste en Support Technique",
      description:
        "Spécialiste en Support Technique pour l'entreprise Américaine Fitbit. Support en espagnol, anglais et français par téléphone, par email et sur les réseaux sociaux.",
      date: "Septembre 2015 - Août 2016",
      tag: "emploi",
      studies: false,
    },
    {
      institution: 'Université de Sofia "St. Kliment Ohridski"',
      location: "Sofia, Bulgarie",
      title: "Licence en Études Hispaniques et Hispano-Américaines (Bac+4)",
      description:
        "Diplôme de traducteur et professeur d'espagnol (Obtenu avec les félicitations du jury).",
      date: "Octobre 2011 - Septembre 2015",
      tag: "études",
      studies: true,
    },
    {
      institution: "Université de Valence",
      location: "Valence, Espagne",
      title: "Études Hispaniques et Hispano-Américaines",
      description: "Erasmus",
      date: "Septembre 2013 - Juin 2014",
      tag: "études",
      studies: true,
    },
  ],
  [LOCALES.SPANISH]: [
    {
      institution: "Quantoom (ex Sadigh Group)",
      location: "Dijon, Francia (Full-Remote)",
      title: "Lead Desarrolladora Full-Stack",
      description: `- desarrollo y mantenimiento de un servicio de monitoreo de máquinas implementado en más de 4300 máquinas, utilizando C# (para Windows), Golang (para Linux) y Swift (para Mac)
- diseño y mantenimiento del front-end (React/Redux) y del back-end (micro-servicios en Node/MongoDB/WebSockets) de dos plataformas SaaS B2B: procesamiento de más de 30K solicitudes al día
- diseño e implementación de una plataforma SaaS de chatbot de voz totalmente personalizable: aproveché Next, TypeScript, Node, MongoDB, PostgreSQL y Asterisk IPBX, reduciendo el tiempo medio de resolución de llamadas en un 20% y mejorando la satisfacción del cliente
- mantenimiento de un servidor Asterisk IPBX VOIP al servicio de un centro de atención al cliente
- liderazgo de proyectos de investigación y desarrollo (I+D) y pruebas de concepto (PoC): compleción con éxito de dos PoC, lo que llevó a la adopción de nuevas tecnologías
- implementación y mantenimiento de una canalización de CI/CD utilizando GitHub Actions, reduciendo el tiempo de implementación en un 30%
- gestión de proyectos y comunicación con los clientes para múltiples proyectos concurrentes en un entorno Agile`,
      date: "Junio 2023 - Hoy",
      tag: "empleo",
      studies: false,
    },
    {
      institution: "Quantoom (ex Sadigh Group)",
      location: "Dijon, Francia (Full-Remote)",
      title: "Desarrolladora Full-Stack",
      description: `- desarrollo de un prototipo de servicio de monitoreo del rendimiento de las máquinas y una aplicación de control remoto para Windows utilizando Golang, un servidor Websocket (Node) y una plataforma web (React) que permite a un usuario tomar el control seguro de una máquina específica
- colaboración en el desarrollo de una plataforma SaaS para empresas de salud y atención social, contribuyendo tanto al front-end (React/Redux) como al back-end (varios micro-servicios usando Node/Express, MongoDB)
- mplementé nuevas funciones y corregí errores en una aplicación móvil multiplataforma en React Native`,
      date: "Abril 2021 - Junio 2023",
      tag: "empleo",
      studies: false,
    },
    {
      institution: "53JS",
      location: "Niza, Francia",
      title: "Desarrolladora JavaScript Full-Stack",
      description:
        "- desarrollo de una plataforma SaaS de streaming y VOD: contribución significativa al front-end (React/Redux) y back-end (Node.js/Express.js, MongoDB), incluyendo el desarrollo de une interfaz de usuario responsive, comunicación en tiempo real (WebRTC, WebSockets) y múltiples endpoints API",
      date: "Dicicembre 2019 - Diciembre 2020",
      tag: "empleo",
      studies: false,
    },
    {
      institution: "Instituto del Comercio y de los Servicios (ICS)",
      location: "Niza, Francia",
      title: "Desarrollo y Diseño de Aplicaciones Web y Móviles",
      description: "Título profesional (nivel Grado)",
      date: "Noviembre 2019 - Noviembre 2020",
      tag: "estudios",
      studies: true,
    },
    {
      institution: "Rheveo",
      location: "Sophia Antipolis, Francia",
      title: "Desarrolladora Front-End",
      description:
        "- participación en el desarrollo de la Interfaz Usuario de una plataforma web que une Inteligencia Artificial y Psicología con el fin de evaluar y mejorar el rendimiento de las empresas a través del compromiso de sus colaboradores. (Vue.js, Element UI, JavaScript, CSS/Sass, Bootstrap, HTML)",
      date: "Junio 2019 - Julio 2019",
      tag: "empleo",
      studies: false,
    },
    {
      institution: "Instituto del Comercio y de los Servicios (ICS)",
      location: "Niza, Francia",
      title: "Dessarollo Web y Móvil",
      description: "Título Profesional (BAC+2)",
      date: "Marzo 2019 - Octubre 2019",
      tag: "estudios",
      studies: true,
    },
    {
      institution: "Inti Formation",
      location: "Sophia Antipolis, Francia",
      title: "Desarrollador Java/JEE",
      description: "Formación en desarrollo de software en Java/JEE",
      date: "Diciembre 2018 - Marzo 2019",
      tag: "estudios",
      studies: true,
    },
    {
      institution: "Universidad de Niza Sophia Antipolis",
      location: "Niza, Francia",
      title: "Master en Estudios Hispánicos e Hispano-Americanos",
      description:
        "Master de Investigación en Lenguas, Litteraturas y Linguística Hispánicas e Hispano-Americanas",
      date: "Agosto 2016 - Septiembre 2018",
      tag: "estudios",
      studies: true,
    },
    {
      institution: "TELUS International Europe",
      location: "Sofía, Bulgaria",
      title: "Especialista en Soporte Técnico (Fitbit)",
      description:
        "Especialista en Soporte Técnico para la empresa Americana Fitbit. Soporte en español, inglés y francés por teléfono, por correo electrónico y en las redes sociales.",
      date: "Septiembre 2015 - Agosto 2016",
      tag: "empleo",
      studies: false,
    },
    {
      institution: 'Universidad de Sofía "St. Kliment Ohridski"',
      location: "Sofía, Bulgaria",
      title: "Grado en Estudios Hispánicos e Hispano-Americanos",
      description: "Diploma de traductor y profesor de español.",
      date: "Octubre 2011 - Septiembre 2015",
      tag: "estudios",
      studies: true,
    },
    {
      institution: "Universidad de Vanlencia",
      location: "Valencia, España",
      title: "Estudios Hispánicos e Hispano-Americanos",
      description: "Erasmus",
      date: "Septiembre 2013 - Junio 2014",
      tag: "estudios",
      studies: true,
    },
  ],
  [LOCALES.BULGARIAN]: [
    {
      institution: "Quantoom (ex Sadigh Group)",
      location: "Дижон, Франция (Full-Remote)",
      title: "Lead Full-Stack Разработчик",
      description: `- разработка и поддържка на услуга за мониторинг на компютърни машини, инсталирана на над 4300 устройства, разработена с C# (за Windows), Golang (за Linux) и Swift (за Mac)
- проектиране и поддръжка на front-end (React/Redux) и back-end (Node/MongoDB/WebSockets) услугите на две B2B SaaS платформи, обработващи над 30 000 заявки дневно
- проектиране и реализация на SaaS платформа за напълно персоналиризуем телефонен гласов чатбот, с Next, TypeScript, Node, MongoDB, PostgreSQL и Asterisk IPBX
- поддръжка на Asterisk IPBX VOIP телефонен сървър, администриращ център за обслужване на клиенти
- извършване на R&D дейност и разработка на PoC проекти: резултатът доведе до усвояването на нови технологии за бъдещи проекти
- реализажия и поддръжка на CI/CD pipeline, използвайки GitHub Actions, намалявайки времето за диплоймънт с 30%
- управление на проекти и водене на комуникацията с клиенти по няколко паралелни проекта в Agile среда`,
      date: "Юни 2023 - Настояще",
      tag: "работа",
      studies: false,
    },
    {
      institution: "Quantoom (ex Sadigh Group)",
      location: "Дижон, Франция (Full-Remote)",
      title: "Full-Stack Разработчик",
      description: `- разработка на прототип на система за мониторинг на машини и програма за дистанционно управление за Windows, използвайки Golang, Websocket сървър (Node) и уеб платформа (React), предоставяща на потребителя сигурен достъп до специфична машина
- участие в разработването на SaaS платформа за компании в социално-медицинския сектор, с принос както към front-end (React/Redux), така и към back-end (Node/Express, MongoDB) услугите
- разработване на нови функционалности и отстраняване на бъгове в кросплатформено мобилно приложение, разработено с React Native`,
      date: "Април 2021 - Юни 2023",
      tag: "работа",
      studies: false,
    },
    {
      institution: "53JS",
      location: "Ница, Франция",
      title: "Full-Stack JavaScript Разработчик",
      description:
        "- участие в разработката на SaaS платформа за стрийминг и VOD: съществен принос към front-end (React/Redux) и back-end (Node.js/Express.js, MongoDB) услугите, с реализация на responsive потребителски интерфейс, комуникация в реално време (WebRTC, WebSockets) и множество API endpoints.",
      date: "Декември 2019 - Декември 2020",
      tag: "работа",
      studies: false,
    },
    {
      institution: "Институт за Търговия и Услуги (ICS)",
      location: "Ница, Франция",
      title: "Разработване и Дизайн на Уеб и Мобилни Апликации",
      description: "Професионално ниво Бакалавър ",
      date: "Ноември 2019 - Ноември 2020",
      tag: "образование",
      studies: true,
    },
    {
      institution: "Rheveo",
      location: "Софиа-Антиполис, Франция",
      title: "Front-End Разработчик",
      description:
        "участие в разработването на потребителски интерфейс за уеб платформа, която съчетава Изкуствен Интелект и Психология с цел оценка и подобряване на производителността на предприятията чрез ангажираността на служителите. (Vue.js, Element UI, JavaScript, CSS/Sass, Bootstrap, HTML)",
      date: "Юни 2019 - Юли 2019",
      tag: "работа",
      studies: false,
    },
    {
      institution: "Институт за Търговия и Услуги (ICS)",
      location: "Ница, Франция",
      title: "Уеб Разработчик",
      description: "Професионална квалификация",
      date: "Март 2019 - Октомври 2019",
      tag: "образование",
      studies: true,
    },
    {
      institution: "Inti Formation",
      location: "Софиа-Антиполис, Франция",
      title: "Разработване на Софтуер с Java/JEE",
      description: "Интензивно обучение",
      date: "Декември 2018 - Март 2019",
      tag: "образование",
      studies: true,
    },
    {
      institution: "Университет Nice Sophia Antipolis",
      location: "Ница, Франция",
      title: "Магистърска степен по Испанистика",
      description: "Магистърска степен",
      date: "Август 2016 - Септември 2018",
      tag: "образование",
      studies: true,
    },
    {
      institution: "TELUS International Europe",
      location: "София, България",
      title: "Technical Support Specialist (Fitbit)",
      description:
        "Technical Support Specialist с испански, френски и английски език (телефон, имейл, социални мрежи).",
      date: "Септември 2015 - Август 2016",
      tag: "работа",
      studies: false,
    },
    {
      institution: 'Софийски Университет "Св. Климент Охридски"',
      location: "София, България",
      title: "Бакалавърска степен по Испанска филология",
      description: "Диплома за преводач, учител по испански език.",
      date: "Октомври 2011 - Септември 2015",
      tag: "образование",
      studies: true,
    },
    {
      institution: "Universitat de València",
      location: "Валенсия, Испания",
      title: "Испанистика",
      description: "Обучение по програма Еразъм",
      date: "Септември 2013 - Юни 2014",
      tag: "образование",
      studies: true,
    },
  ],
};

export default data;
