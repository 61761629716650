import { LOCALES } from "../constants";

const fr = {
  [LOCALES.FRENCH]: {
    menu: {
      home: "Accueil",
      about: "À propos",
      skills: "Compétences",
      projects: "Projets",
      experience: "Expérience",
      contact: "Contact",
      resume: "CV",
    },
    home: {
      presentation: "Bonjour ! Je m'appelle ",
      profession: "Je suis ",
      contactBtn: "Contactez-moi",
    },
    about: {
      heading: "À propos de moi",
      presentation:
        "Bonjour ! 👋🏻 Je m'appelle Dilyana et je suis développeuse full-stack, basée sur Nice, France.",
      info1:
        "Je suis passionnée par la programmation 💻 et les langues étrangères 🗺️ . J'aime créer des applications web et mobiles esthétiques et j'ai pour objectif d'écrire un code bien structuré et de qualité, ainsi que de m'améliorer en permanence.",
      info2:
        "Au cours des cinq dernières années, j'ai développé et contribué à de nombreux projets, notamment des services de surveillance de machines en temps-réel déployés sur des milliers de machines, et deux plateformes SaaS B2B traitant plus de 30 000 requêtes par jour. J'ai conçu et implémenté une plateforme SaaS de chatbot vocal entièrement personnalisable, améliorant considérablement les indicateurs de service client. Mon expérience englobe également la direction de projets de R&D et de preuves de concept, la mise en œuvre d'un pipeline CI/CD ayant réduit les temps de déploiement, la gestion de plusieurs projets simultanés en environnement Agile, et des contributions significatives à plusieurs plateformes SaaS, notamment dans les secteurs de la santé et des soins sociaux, et de la vidéo à la demande et streaming. J'ai également assuré la maintenance d'un serveur VoIP et développé des prototypes, démontrant mon expertise en développement front-end et back-end pour des applications web et mobiles.",
      info3:
        "Je parle courament français 🇫🇷 , anglais 🇬🇧 , espagnol 🇪🇸 et bulgare 🇧🇬. Je suis diplômée d'un Master en",
      mastersDegree: '"Études Hspaniques et Hispano-Américaines" ',
      info4: "et j'ai un Titre Professionnel de ",
      bachelorsDegree: '"Concepteur Développeur d\'Applications."',
      info5:
        'Je me "nourris" principalement de code et de bon café ☕ . Je suis motivée et j\'aime acquerir de nouvelles compétences, adopter de bonnes pratiques 📚 , me tenir à jour des technologies les plus récentes et les mettre en pratique en développant différents projets 👩🏼‍🔬 .',
      interestsHeading: "Intérêts : ",
      info6:
        'programmation, apprentissage, développement personnel, langues étrangères,  jeux vidéo, retro gaming, jeux de plateau et électronique. Bon, je suis une "geek" 👩🏼‍💻 .',
    },
    skills: {
      heading: "Compétences",
    },
    projects: {
      heading: "Projets",
      technologies: "Technologies",
      description: "Description",
      visitSiteBtn: "Visiter Le Site",
      seeMoreBtn: "Voir Plus",
      watchDemoBtn: "Voir Demo",
      checkGithubBtn: "Voir Github",
    },
    experience: {
      heading: "Experience",
    },
    contact: {
      heading: "Contactez-moi",
      form: {
        name: "Nom et Prénom",
        email: "Adresse Email",
        subject: "Sujet",
        message: "Message",
        sendButton: "Envoyer",
        confirmation: "Message Envoyé!",
        error: "Une erreur est survenue.",
      },
    },
    flags: {
      british: "drapeau britanique",
      bulgarian: "drapeau bulgare",
      french: "drapeau français",
      spanish: "drapeau espagnol",
    },
  },
};

export default fr;
